form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 0 auto;
}

label {
  width: 100%;
  margin-bottom: 1rem;
  font-weight: bold;
}

p.error {
  margin: 0;
  color: rgb(0, 21, 255);
}

input {
  padding: 0.5rem;
  border: 3px solid black;
  height: 3.5rem;
  border-radius: 15px;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  border-color: rgb(22, 151, 215); /* голубой цвет границы при фокусировке */
  border-width: 8px;
}

input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 1.3rem; /* размер шрифта */
  color: black;
  opacity: 0.5;
  font-weight: bold;
}


input[type="text"],
input[type="email"],
input[type="number"] {
  width: calc(100% - 4px);
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button[type="submit"] {
  background-color: rgb(22, 151, 215);
  color: #fff;
  padding: 1.3rem 4rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-top: 3vh;
  margin-bottom: 2rem;
}

button[type="submit"]:hover {
  background-color: rgb(89, 187, 236);
}

.in_check {
  width: 100%;
  display: flex;
  align-items: center;
}
.in_check p {
  padding-left: 20px;
  color: black;
}
.check_error {
  color: red;
  align-self: flex-start;
  margin-bottom: 1rem;
}
.in_check input {
  width: 30px;
}
