p {
  font-size: large;
  opacity: 0.5;
}

footer {
  display: flex;
  width: 80%;
  height: auto;
  margin-top: 10vh;
}